import axios from '@/libs/axios'

export var countries = new Promise((resolve, reject) => {
    axios.get(`/api/assets?method=select-countries`)
        .then(response => {
            resolve(response.data.data)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})

export var situationMatrimoniales = [
    //
    {
        key: "celibataire",
        text: "Celibataire"
    },
    {
        key: "marie",
        text: "Marié(e)"
    },
    {
        key: "veuf",
        text: "Veuf(ve)"
    },
    {
        key: "divorce",
        text: "Divorcé(e)"
    },
    {
        key: "union-civille",
        text: "Union civille"
    },
    {
        key: "en-separation",
        text: "En séparation"
    }
];

// 
export var civilities = [
    // 
    {
        key: "M",
        text: "Monsieur"
    },
    {
        key: "Mme",
        text: "Madame"
    },
    {
        key: "Mlle",
        text: "Mademoiselle"
    },
    {
        key: "Dr",
        text: "Docteur"
    },
    {
        key: "Me",
        text: "Maître"
    },
    {
        key: "Pr",
        text: "Professeur "
    }
];

// 
export var genres = [
    // 
    {
        key: "homme",
        text: "Homme"
    },
    {
        key: "femme",
        text: "Femme"
    }
];

// 
export var grades = [
    // 
    {
        key: 1,
        text: "G1"
    },
    {
        key: 2,
        text: "G2"
    },
    {
        key: 3,
        text: "G3"
    },
    {
        key: 4,
        text: "G4"
    },
    {
        key: 5,
        text: "G5"
    },
    {
        key: 6,
        text: "A1"
    },
    {
        key: 7,
        text: "A2"
    },
    {
        key: 8,
        text: "A3"
    },
    {
        key: 9,
        text: "A4"
    },
    {
        key: 10,
        text: "Master"
    }
];

//
export var typePieces = [{
        key: "cni",
        text: "Carte Nationale d'Identité"
    },
    {
        key: "passeport",
        text: "Passeport"
    },
    {
        key: "permis-conduire",
        text: "Permis de conduire"
    },
    {
        key: "carte-sejour",
        text: "Carte de Séjour"
    },
    {
        key: "carte-consulaire",
        text: "Carte Consulaire"
    }
];