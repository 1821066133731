<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-list-group
          v-if="data"
          flush
        >
          <b-list-group-item>Pseudo: <b>{{ data.username }}</b></b-list-group-item>
          <b-list-group-item>Nom: <b>{{ data.lastname }}</b></b-list-group-item>
          <b-list-group-item>Prénom(s): <b>{{ data.firstname }}</b></b-list-group-item>
          <b-list-group-item>Contact: <b>{{ data.contact }}</b></b-list-group-item>
          <b-list-group-item>Adresse e-mail: <b>{{ data.email }}</b></b-list-group-item>
          <b-list-group-item>Grade: <b>{{ grades[data.grade-1].text }}</b></b-list-group-item>
          <b-list-group-item>Fonction: <b>{{ data.occupation }}</b></b-list-group-item>

          <b-list-group-item>Date de création:
            <b>{{
              this.$coddyger.cdgDateFormat(data.created_at, true)
            }}</b></b-list-group-item>
          <b-list-group-item>Dernière mis à jour:
            <b>{{
              this.$coddyger.cdgDateFormat(data.updated_at, true)
            }}</b></b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import { grades } from "@/utils/constants";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }

    return {
      grades,
    }
  },
})
</script>
