export default {
  ADD_ITEM(state, item) {
    console.log('ENTERRING SAVE...')
    state.data.unshift(item)
  },
  UPDATE_PHOTO(state, item) {
    console.log('ENTERRING SAVE...')
    state.photo = item
  },
  SET_LIST(state, payload) {
    console.log('ENTERRING LIST...')
    state.data = payload
  },
  UPDATE_ITEM(state, payload) {
    console.log('ENTERRING UPDATE...')
    const dataIndex = state.data.findIndex(p => p.slug === payload.slug)
    Object.assign(state.data[dataIndex], payload)
  },
  REMOVE_ITEM(state, itemId) {
    console.log('ENTERRING REMOVE...')
    const ItemIndex = state.data.findIndex(p => p.slug === itemId)
    state.data.splice(ItemIndex, 1)
  },
  // STRANGER MUTATIONS
  SET_FILE(state, payload) {
    console.log('GATHERING FILE...')
    state.file = payload
  },
}
