<template>
  <div>
    <b-alert v-if="cantLoadData" variant="danger" show>
      <h4 class="alert-heading">Erreur</h4>
      <div class="alert-body">
        <span>{{ cantLoadDataText }}</span>
      </div>
    </b-alert>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <single-view :data="singleData" />
      </b-modal>

      <!-- SAVE/EDIT RECORD -->
      <b-modal
        id="setDataModal"
        ref="setDataModal"
        :title="
          Object.entries(this.modaldata.data).length === 0
            ? 'Nouveau'
            : 'Modification'
        "
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          {{
            Object.entries(this.modaldata.data).length === 0
              ? "Nouveau"
              : `Modification ${this.modaldata.data.title}`
          }}
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <b-row>
            <b-col md="4">
              <!-- GRADE -->
              <b-form-group label="* Grade" label-for="grade">
                <v-select
                  id="grade"
                  v-model="formdata.grade"
                  label="text"
                  value="key"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner un grade"
                  :options="grades"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <!-- LASTNAME -->
              <b-form-group label="* Nom" label-for="lastname">
                <b-form-input
                  id="lastname"
                  v-model="formdata.lastname"
                  placeholder="Ex: Grébé"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- FIRSTNAME -->
              <b-form-group label="* Prénom(s)" label-for="firstname">
                <b-form-input
                  id="firstname"
                  v-model="formdata.firstname"
                  placeholder="Ex: Jordan Jones"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- CONTACT -->
              <b-form-group label="* Contact" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="formdata.contact"
                  placeholder="Ex: 0506988784"
                  autocomplete="off"
                  autofill="off"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <!-- PASSWORD -->
              <b-form-group>
                <label for="password">* Mot de passe</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="password"
                    v-model="formdata.password"
                    :type="passwordFieldType"
                    placeholder="············"
                    autocomplete="off"
                    autofill="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- PASSWORD CONFIRMATION -->
              <b-form-group>
                <label for="passwordConf">* Confirmation du mot de passe</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="passwordConf"
                    v-model="formdata.password_conf"
                    :type="passwordFieldType"
                    placeholder="············"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('save')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow" />
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" @click="openSaveDialog">
              Nouveau
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
        :busy.sync="tabledata.isBusy"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner small type="grow" />
            <strong> Veuillez patienter...</strong>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click.stop="openSave(data.item.slug, 'update')"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAlert,
  BInputGroupAppend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import UserStore from "@/store/app/user/";
import * as payloads from "./payloads.json";
import SingleView from "./components/single-view.vue";
import { grades } from "@/utils/constants";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAlert,
    BInputGroupAppend,
    BInputGroup,
    BDropdown,
    BDropdownItem,

    vSelect,
    SingleView,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      cantLoadData: false,
      cantLoadDataText: payloads.cantLoadDataText,
      payloads: {},
      isLoading: false,
      singleData: [], // For single view mode
      locale: "fr",
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, 30],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        columns: [
          {
            key: "index",
            label: "#",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          {
            key: "lastname",
            label: "Nom",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          {
            key: "firstname",
            label: "Prenom(s)",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          // {
          //   key: "grade",
          //   label: "grade",
          //   sortable: true,
          //   formatter: (val) => `${this.tabledata.grades[val - 1].text}`,
          // },
          {
            key: "created_at",
            label: "Enregistré le",
            sortable: true,
            formatter: (val) =>
              `${val !== null ? `${this.$coddyger.cdgDateFormat(val)}` : ""}`,
          },
          { key: "actions" },
        ],
        searchTerm: "",
        grades: grades,
      },
      // Data NEW/EDIT Modal
      grades: grades,
      formdata: {
        slug: null,
        // -FORM-PROPERTIES-
        contact: "",
        lastname: "",
        firstname: "",
        password: "",
        password_conf: "",
        grade: "",
        options: {
          date: {
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      },
    };
  },
  computed: {
    buildData() {
      const { data } = this.$store.state.UserStore;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      }
      return false;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore();
    // Build payloads
    this.buildPayloads();
    // Build store data
    this.buildStoreData();
  },

  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!UserStore.isRegistered) {
        this.$store.registerModule("UserStore", UserStore);
        UserStore.isRegistered = true;
      }
    },
    /* ----------------------------------------
    | APP PAYLOADS
      ---------------------------------------- */
    buildPayloads() {
      let { path } = this.$router.history.current;
      const getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      this.payloads = {
        route: `apps-${path}`,
        routeSave: `apps-${path}-save`,
        paths: {
          select: `/api/${path}/select`,
          save: `/api/${path}/save`,
          edit: `/api/${path}/edit`,
          remove: `/api/${path}/remove`,
        },
      };
    },
    buildStoreData() {
      this.tabledata.isBusy = true;
      this.$store
        .dispatch("UserStore/fetch", this.payloads.paths.select)
        .then(() => {
          this.tabledata.isBusy = false;
        })
        .catch(() => {
          this.tabledata.isBusy = false;
          this.cantLoadData = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erreur",
              icon: "WarningIcon",
              variant: "danger",
              text: "Impossible de charger la liste des données. Merci de réessayer plutard.",
            },
          });
        });
    },

    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === "save") {
        this.$refs.setDataModal.hide();
        this.resetValues();
      } else if (object === "edit") {
        this.$refs.editModal.hide();
        this.resetValues();
      } else {
        //this.$refs.importModal.hide()
      }
    },
    openSaveDialog() {
      this.$refs.setDataModal.show();
    },
    openImportDialog() {
      this.$refs.importModal.show();
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: { ...this.$route.query, pid: id },
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          } else {
            this.revertUrl();
          }
        });
    },
    deleteData() {
      const id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("UserStore/remove", {
            item: id,
            path: this.payloads.paths.remove,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Action Réussie",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Enregistrement supprimé avec succès!",
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            this.$vs.notify({
              title: "Erreur",
              text: error,
              color: "danger",
              icon: "error",
            });
          });
      }
    },
    // REVERT URL TO THE PREVIOUS
    revertUrl() {
      this.$router.replace({ name: this.payloads.route });
    },
    // MODAL FORM TOOL --------------
    resetValues() {
      let current = this.formdata;

      for (var key in current) {
        if (current.hasOwnProperty(key)) {
          if (typeof current[key] === "string") {
            current[key] = "";
          } else if (typeof current[key] === "number") {
            current[key] = 0;
          } else if (typeof current[key] === "object") {
            current[key] = [];
          } else if (current[key] instanceof Array) {
            current[key] = [];
          } else {
            current[key] = "";
          }
        }
      }

      this.modaldata.data = {};
      this.formdata = current;
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.grade === "") {
        this.swalError("Erreur", this.buildRequiredMessage("grade"), "error");
      } else {
        let global = {};
        const formData = this.formdata;
        formData.grade = this.formdata.grade ? this.formdata.grade.key : "";

        global = {
          formdata: formData,
          path:
            this.formdata.slug !== null && this.formdata.slug !== ""
              ? this.payloads.paths.edit
              : this.payloads.paths.save,
        };

        this.isLoading = true;

        if (this.formdata.slug !== null && this.formdata.slug !== "") {
          this.$store
            .dispatch("UserStore/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Action Réussie",
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: "Modification effectué avec succès!",
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.$store
            .dispatch("UserStore/save", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Action Réussie",
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: "Enregistrement modifié avec succès!",
                },
              });
              this.resetValues();
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        }
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    buildEditModal(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.resetValues();
      } else {
        const { slug, title, category, categoryName, content } = JSON.parse(
          JSON.stringify(data)
        );

        this.formdata.slug = slug;
        this.formdata.title = title;
        this.formdata.category = {
          key: category,
          text: categoryName,
        };
        this.formdata.content = content;
      }

      this.$refs.setDataModal.show();
    },
    openSave(id = "", action = "save") {
      if (action == "save") {
        this.$router.push({ name: this.payloads.routeSave });
      } else {
        this.$router.push({ name: this.payloads.routeSave, params: { id } });
      }
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
